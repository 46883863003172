import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1_sass@1.77.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1_sass@1.77.1/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/Snackbar/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Footer/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Footer/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1_sass@1.77.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Poppins-Light.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"Poppins\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Header/_Annotation.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Header/header.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/global/global.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organisms/Header/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/Fathom.js")